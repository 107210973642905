<template>
  <section class="event">
    <div
      v-if="event.image != '' && event.image != null"
      class="banner"
      :style="{
        backgroundImage:
          'url(' +
          img_url +
          encodeURIComponent(event.image).replace('%2F', '/') +
          ')',
      }"
    ></div>
    <div class="container-event">
      <div
        class="aside"
        :style="
          event.category == 'News'
            ? {
                backgroundImage:
                  'url(' +
                  img_url +
                  encodeURIComponent(event.image2).replace('%2F', '/') +
                  ')',
              }
            : {
                backgroundImage:
                  'none',
              }
        "
      >
        <div
          class="event-info"
          v-if="event.category == 'event' || event.category == 'Event'"
        >
          <div class="date-container">
            <p class="date">{{ event.date }}</p>
            <p class="hour">{{ event.start_hour.slice(0, 5) }}</p>
          </div>
          <div class="address-container">
            <p class="location">
              <span class="icon-icon-location"></span> {{ event.location }}
            </p>
            <p class="address">{{ event.address }}</p>
          </div>
          <div class="price-container">
            <p v-if="event.price > 0" class="price">{{ event.price }} CHF</p>
            <p v-if="event.price == 0" class="price">
              {{ content.events.free }}
            </p>
          </div>
          <a @click="registerToEvent" class="button-fill">{{
            content.events.btn_inscrever
          }}</a>
        </div>
        <!-- <div v-else>
          <img :src="img_url + event.image2" />
        </div> -->
      </div>
      <div class="description">
        <p v-if="event.category == 'Event'" class="category">
          {{ content.events.event }}
        </p>
        <p v-if="event.category == 'News'" class="category">
          {{ content.events.news }}
        </p>
        <h4>{{ event.title }}</h4>
        <p class="description_event" v-html="event.description"></p>
      </div>
    </div>
    <v-dialog v-model="dialog2" width="500">
      <v-card class="mx-auto dialog-card">
        <h4 v-html="content.dialogs.events.title"></h4>
        <p v-html="content.dialogs.events.description"></p>
        <a v-on:click="dialog2 = false"
          ><span class="icon-icon-close"></span
        ></a>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  data() {
    return {
      dialog2: false,
      item: {},
      apiCallResult: {},
      apiBody: {},

      // initializeEP: "events/get-single/",
      n: 0,
    };
  },
  computed: {
     initializeEP() {
      let temp = "events/"+this.version+"bo-get-single/"
      return temp
    },
    event() {
      // this.n;
      let temp = {};

      temp = this.item;
      if (
        typeof temp.content != "undefined" &&
        typeof temp.content == "string"
      ) {
        let content = JSON.parse(temp.content);
        let lang = this.pageLanguage.toLowerCase();
        content = content[lang];

        for (let k in content) {
          temp[k] = content[k];
        }

        temp.content = content;
      }

      return temp;
    },
    login() {
      this.n;
      let res =
        window.localStorage.getItem("loggedIn") == "true" ? true : false;

      return res;
    },
  },
  async mounted() {
    await this.initializeSingle(this.initializeEP + this.$route.params.id);

    this.n += 1;
  },
  methods: {
    async registerToEvent() {
      if (!this.login) {
        this.changeModal("login");
      } else {
        let user_id = window.localStorage.getItem("user_id");
        let event_id = this.$route.params.id;

        this.apiBody = {
          user_id,
          event_id,
        };

        this.$store.dispatch("eventRegister", this);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.banner {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include responsive(0, 512px) {
    height: 200px;
  }
}
.container-event {
  position: relative;
  display: flex;
  @include responsive(0, 768px) {
    display: block;
  }
  .aside {
    width: 300px !important;
    background-color: $light-greyII;
    min-height: calc(100vh - 627px);
    display: inline-block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include responsive(0, 768px) {
      display: block;
      width: 100% !important;
      min-height: 0;
      // background-color: transparent;
    }
    img {
      width: 100%;
      margin: 0 auto;
      @include responsive(0, 1024px) {
        // display: none;
      }
    }
    .event-info {
      padding: 100px 40px;
      @include responsive(0, 768px) {
        width: 100%;
      }
      @include responsive(0, 768px) {
        padding: 50px 30px;
      }
      @include responsive(0, 512px) {
        padding: 50px 30px;
      }
      p {
        text-align: left;
        color: $dark-grey;
        font-size: 16px;
        line-height: 25px;
        @include responsive(0, 768px) {
          text-align: center;
        }
      }
      .date-container {
        margin-bottom: 50px;
        @include responsive(0, 768px) {
          width: 100%;
          margin-bottom: 30px;
        }
        .date {
          font-weight: 600;
        }
        .hour {
        }
      }
      .address-container {
        margin-bottom: 50px;
        @include responsive(0, 768px) {
          width: 100%;
          margin-bottom: 30px;
        }
        .location {
          font-weight: 600;

          span {
            font-family: "icomoon";
            font-size: 18px;
            color: $dark-grey;
          }
          .icon-icon-location:before {
            content: "\e906";
          }
        }
        .address {
        }
      }
      .price-container {
        margin-bottom: 50px;
        @include responsive(0, 768px) {
          width: 100%;
          margin-bottom: 30px;
        }
        .price {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .button-fill {
        @include responsive(0, 768px) {
          margin: 0 auto;
        }
      }
    }
  }
  .description {
    min-height: calc(100vh - 627px);
    padding: 60px 80px;
    max-width: 1000px;
    @include responsive(0, 1024px) {
      padding: 60px 40px;
    }
    @include responsive(0, 512px) {
      padding: 40px 30px;
    }
    .category {
      color: $dark-grey;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-size: 14px;
      &:after {
        content: "";
        width: 40px;
        height: 1px;
        background-color: $dark-grey;
        display: block;
        margin-top: 10px;
      }
    }
    h4 {
      color: $dark-grey;
      margin-top: 30px;
    }
    .description_event {
      font-size: 17px;
      font-family: $font2;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 17px;
        font-family: $font2;
        span {
          font-size: 17px;
          font-family: $font2;
        }
      }
    }
  }
}

.dialog-card {
  text-align: center;
  padding: 80px !important;
  background-color: $red;
  h4 {
    text-align: center !important;
    margin-bottom: 15px !important;
    line-height: 30px;
    text-transform: none;
    color: white;
  }
  p {
    text-align: center !important;
    color: white;
    margin-bottom: 0;
    margin-top: 40px;
  }
  a {
    color: white !important;
    font-family: "icomoon";
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>